import barba, { ITransitionPage } from "@barba/core";
import { gsap } from "gsap";
import $ from "jquery";

//import { setupModal } from "./modal";
import { closeHamburger } from "./hamburger";

import "../scss/main.scss";

// LMB 3/8/24 Barba isn't working with Sprig filters on the Slabs page

// const genericTransition: ITransitionPage = {
//   // Before current component leaves, set next to not take up dom space
//   before(data: any) {
//     gsap.set(data.next.container, {
//       opacity: 0,
//       position: "absolute",
//       force3D: true,
//       ease: "power2.out",
//     });
//   },
//   // During leave, animate current to no opacity
//   leave(data: any) {
//     return new Promise((resolve: any) => {
//       gsap.to(data.current.container, {
//         opacity: 0,
//         duration: 1,
//         force3D: true,
//         ease: "power2.out",
//         onComplete: () => resolve(),
//       });
//     });
//   },
//   // Once next container enters, animate it to opacity 1
//   enter(data: any) {
//     window.scrollTo(0, 0);

//     gsap.set(data.next.container, {
//       position: "relative",
//     });
//     gsap.set(data.current.container, {
//       display: "none",
//     });
//     return new Promise((resolve: any) => {
//       gsap.to(data.next.container, {
//         opacity: 1,
//         duration: 0.5,
//         ease: "power2.out",
//         force3D: true,
//         onComplete: () => resolve(),
//       });
//     });
//   },
// };

// // Hooks for page transitions
// barba.init({
//   prefetchIgnore: true,
//   timeout: 5000,
//   transitions: [
//     // Same route animation
//     {
//       name: "self",
//     },
//     // Default pages
//     {
//       name: "default-transition",
//       sync: true,
//       ...genericTransition,
//     },
//   ],
// });

// barba.hooks.after(() => {
//   setupModal();
//   closeHamburger();
// });

$(() => {
  //setupModal();
  closeHamburger();
});
